/* globals must be listed below to pass JSHint */
/* globals console, getRequestPath, searchListForItem, showAlert, renewServerSession, requestTimeout, refreshAllocationPage,
 showAjaxLoading, hideAjaxLoading, sortByProperty, searchList, isNumeric, searchArrayForObject, clearAuthorisation,
 getBarcodeMapping, getIncidentCodes, sendTransactions, logOut, getCurrentPage, isTimeOutOfSync, getConfig,
 saveAndCheckServerTime, isScannerTimeCorrect, showIncorrectTimeMessage, setCaskConditionCodes, saveEventGroupsToStorage,
 setFillingLines, getScannerLocationCode, setLocationWarehouses, saveLabels, setLocations, getScannerCompany, saveIncidentsToStorage */

/**
 * @description Ping the server to check it is up
 * @param {dramscanConfig} localConfig
 * @returns {jQuery|{getAllResponseHeaders: (function(): *), abort: (function(*=): jqXHR), setRequestHeader: (function(*=, *): jqXHR), readyState: number, getResponseHeader: (function(*): *), overrideMimeType: (function(*): jqXHR), statusCode: (function(*=): jqXHR)}|HTMLElement|(function(*=, *=): *)}
 */
function pingServer(localConfig) {
  // making a request to the server. if we cannot reach the server then were essentially offline.
  // returns the request to be used elsewhere in a $.when()
  // IIS can map the _oeping to the correct destination when using rewrites.
  "use strict";
  var requestUrl = localConfig.apiUrl + "/DramscanService/rest/_oeping";
  return $.ajax({url: requestUrl, type: "GET", timeout:requestTimeout, dataType: "json", cache: false});
}

/**
 * @description Defined function to call the network recheck, with true values.
 */
function checkNetworkStatusAndSetTimer ()
{
  "use strict";
  checkNetworkStatus(true, true);
  // Technically the above might be called when the sendData should be false, but this is never the case in current code.
}

/**
 * @description Checks the network status of the device and application.
 * @param {Boolean} sendData If true then transactions will be sent if the network is online.
 * @param {Boolean} setRecheckTimer If true then the function will call itself again in one minute.
 */
function checkNetworkStatus(sendData,setRecheckTimer) {
  "use strict";
  if(typeof sendData === "undefined") sendData = false;
  if(typeof setRecheckTimer === "undefined") setRecheckTimer = false;

  /* try and register the service worker for caching */
  registerServiceWorker();

	/**
	 * @param {configLoadedCallback}
	 */
	getConfig(function(localConfig) {
		$.when(pingServer(localConfig)).always(function(arg1,status) {
			if(status ==="success") {
			  // data = arg1, XHR = arg3
			  $('#onlinestatusbutton').text('Online').removeClass("offline").addClass("online");
			  applicationState.networkOnline = true;
				if(sendData) {
					sendTransactions(true);
				}
				//This code breaks something somewhere and caused freezing
				//saveAndCheckServerTime(xhr.getResponseHeader("Date"));
			} else {
				// XHR = arg1, error = arg3
				$('#onlinestatusbutton').text('Offline').removeClass("online").addClass("offline");
				applicationState.networkOnline = false;
				if(getCurrentPage() !== "dslogin") {
					isScannerTimeCorrect(function(correct) {
						if(!correct) {
							showIncorrectTimeMessage(function() {
								logOut(false);
							});
						}
					});
				}
				if(setRecheckTimer) {
					setTimeout(checkNetworkStatusAndSetTimer, 60000);
				}
			}
		}); // when
	});
}

function downloadStandingData(callback) {
  "use strict";
  var scannerCoyno = getScannerCompany();
  var scannerLocation = (sessionStorage.getItem("tempLocation"))? sessionStorage.getItem("tempLocation"): getScannerLocationCode();
  var scanner = parseInt(localStorage.getItem("scannerid"));

  $.ajax({
    url: getRequestPath("settings/DRAMScan?coyno="+scannerCoyno+"&location="+scannerLocation + "&scanner=" + scanner),
    type: "GET",
    crossDomain: true,
    contentType: "application/json; charset=UTF-8",
    timeout:requestTimeout,
    cache: false,
    xhrFields: {
      withCredentials: true
    },
    success: function (data, status, xhr) {

      if (xhr.status === 304) {
        console.log("Settings update returned 304.");
      }
      else {

				/**
				 * @typedef {settingsObject} settings
				 */
				var settings = data.settings[0];

				//Breakdown of setting configuration now categorised for ease. Generic items listed first, followed by related groups.
				//Security - Security settings.
				localStorage.setVariable("security/adminLevel", (settings.iAdminLevel) ? settings.iAdminLevel : 80, false);
				localStorage.setVariable("security/exitLevel", (settings.iExitLevel) ? settings.iExitLevel : 90, false);

				//Settings - Data which is primarily keys, logical switches, and single values.
				localStorage.setVariable("settings/eTag", xhr.getResponseHeader("ETag"), true);
				localStorage.setVariable("settings/useDespatchAllocations", (settings.useDespatchAllocations !== null) ? settings.useDespatchAllocations : false, false); // default to false
				localStorage.setVariable("settings/palletBayCapacity", settings.iPalletBayCapacity,true);
				localStorage.setVariable("settings/palletStackCapacity", settings.iPalletStackCapacity,true);
				localStorage.setVariable("settings/prepopulateManualCaskNumber",settings.prepopulateManualCaskNumber,false);
				localStorage.setVariable("settings/trimFieldWhitespace", settings.trimFieldWhitespace,false);
				localStorage.setVariable("settings/useIncidentApproval", settings.useIncidentApproval,false);
				localStorage.setVariable("settings/downloadContainers", settings.downloadContainers,false);
				localStorage.setVariable("settings/downloadDespatches", settings.downloadDespatches,false);

				localStorage.setVariable("settings/timeout", (settings.iSessionTime) ? settings.iSessionTime.toString() : "30", false);
				localStorage.setVariable("settings/requestTimeout",(settings.iRequestTimeout)? (settings.iRequestTimeout*1000):0, false);
				localStorage.setVariable("settings/downloadAllAllocations", (settings.lAllAllocations !== null) ? settings.lAllAllocations : true, false); // default to true
				localStorage.setVariable("settings/rackLimit", (settings.lRackLimit !== null) ? settings.lRackLimit : false, false);
				localStorage.setVariable("settings/rackLimitLevel", settings.iRackLimit, true);
				localStorage.setVariable("settings/rackLimitWarning", settings.iRackWarning, true);
				localStorage.setVariable("settings/overrideRackBay", settings.cRackedBayTransactions, true);
				localStorage.setVariable("settings/classicBarcodeMapping", settings.cBarcodeLabel,false);
				localStorage.setVariable("settings/scanPrefix", settings.cScanPrefix, true);
				localStorage.setVariable("settings/scanPriority",settings.cBarcodePriority,false);
				localStorage.setVariable("settings/confirmScanSound",settings.lConfirmScanSound,false);
				localStorage.setVariable("settings/hideDoubleScanError",settings.lHideDoubleScanError,false);
				localStorage.setVariable("settings/batchMap", settings.cBatchLabel, true);
				localStorage.setVariable("settings/palletSize", settings.iPalletSize, true);
				localStorage.setVariable("settings/useSWA", settings.lUseSWA, false);
				localStorage.setVariable("settings/useWTL", settings.lUseWTL, false);
				localStorage.setVariable("settings/woodTrackingMap", settings.cWoodLabel,true);
				localStorage.setVariable("settings/woodTrackingTagLength",  settings.iWoodLabelTagLength, true);
				localStorage.setVariable("settings/woodTrackingFormat", settings.cWoodLabelFormat, true);
				localStorage.setVariable("settings/woodTrackingPrefix", settings.cWoodLabelPrefix, true);
				localStorage.setVariable("settings/woodTrackingValidation", (settings.lValidateWoodLabel !== null)? settings.lValidateWoodLabel : false, false);
				localStorage.setVariable("settings/useTrailers", settings.lUseTrailers, false);
				localStorage.setVariable("settings/trailerWarehouse", settings.cTrailerWarehouse, true);
				localStorage.setVariable("settings/maxTransactions", settings.maxTransactions, true);
				localStorage.setVariable("settings/capacityHitAction", settings.capacityHitAction, true);
				localStorage.setVariable("settings/capacityExpiryUnit", settings.capacityExpiryUnit, true);
				localStorage.setVariable("settings/capacityExpiryValue", settings.capacityExpiryValue, true);
				localStorage.setVariable("settings/useRealPallets", settings.useRealPallets, true);
				localStorage.setVariable("settings/requireWoodCodes", settings.requireWoodCodes, true);

				//Data - Data sets of information.
				// todo
				saveIncidentsToStorage((data.incidentGroups)? data.incidentGroups:[]);
				//localStorage.setObject("data/incidentCodes", (data.incidents)? data.incidents:[]);
				localStorage.setObject("data/makes", data.makes);
				localStorage.setObject("data/users", data.users);

				localStorage.setObject("data/transactionCodes", data.transactionCodes);

				saveLabels(settings.cCaskLabel, data.labels);

				// Related groups - Various storage groups, but related keys.
				// Check and save any custom barcode formats
				localStorage.setVariable("settings/useCustomBarcodeFormats",(settings.lCustomBarcodeFormats)? settings.lCustomBarcodeFormats:false,false);
				localStorage.setVariable("settings/useCustomLocationFormats", (settings.useCustomLocationFormats)? settings.useCustomLocationFormats:false, false);
				localStorage.setObject("data/barcodeFormats",(data.barcodeFormats)? data.barcodeFormats: []);

				// Check and save settings for pallet put away start bays
				localStorage.setVariable("settings/checkPalletStartPositions", (settings.checkPalletStartPositions)? settings.checkPalletStartPositions:false,false);
				localStorage.setObject("data/palletStartPositions", (data.palletStartPositions)? data.palletStartPositions: []);

				// call to save filling batches. If none are provided, ensure the data that could be local is cleared.
				saveBatchesToStorage((data.batches)? data.batches:[]);

				//Wood tracking data
				setCaskConditionCodes((data.conditionCodes)? data.conditionCodes : []);
				saveEventGroupsToStorage((data.eventGroups)? data.eventGroups : []);
				setFillingLines((data.fillingLines)? data.fillingLines: []);
				localStorage.setObject("data/emptyWoodLocations", (data.emptyWoodLocations)? data.emptyWoodLocations: []);
				localStorage.setObject("data/caskTypes", (data.caskTypes)? data.caskTypes: []);
				localStorage.setObject("data/woodTypes", (data.woodTypes)? data.woodTypes: []);
				localStorage.setObject("data/spirits", (data.spirits)? data.spirits: []);
				localStorage.setObject("data/blends", (data.blends)? data.blends: []);
				//End of wood tracking data

				//Update locations on the scanner. Then we update warehouses for the current location
        		setLocations(data.locations);
				sessionStorage.removeItem("tempLocation");
				if (getScannerLocationCode()) {
					setLocationWarehouses(null);
				}
        tidySettingsUpdated();
      }

      saveAndCheckServerTime(xhr.getResponseHeader("Date"), function(passed) {
        callback(passed);
      });
    },
    error: function (xhr, status) {
    	if (xhr.status === 401 && canReattemptAfterLogin()) {
    		renewServerSession(function (success) {
    			if (success) {
    				downloadStandingData(callback);
					} else {
						tidySettingsUpdated(); // This is called on error, as keys need to be converted regardless of failures on update.
						showAlert(" Data sync failed with server ("+status+xhr.status+"). Please try again or contact Support.", {callback: function() {
								callback(false);
							}});
					}
				});
			} else {
				tidySettingsUpdated(); // This is called on error, as keys need to be converted regardless of failures on update.
				showAlert(" Data sync failed with server ("+status+xhr.status+"). Please try again or contact Support.", {callback: function() {
						callback(false);
					}});
			}
    }
  });
}
/**
 * @description Cleans up old parameters and old saved LS settings
 * @returns {string} version Version of the routine (call), to determine what is being deleted / updated.
 */
function tidySettingsUpdated() {
  "use strict";
  localStorage.removeItem("tagForSettings");
  localStorage.removeItem("adminlevel");
  localStorage.removeItem("incidents");
  localStorage.removeItem("allocation");
	localStorage.removeItem("exportallocations");
	localStorage.removeItem("tagForTrailers");
	localStorage.removeItem("trailer");
	localStorage.removeItem("data/customLocationMappings");

  //include clearing old storage for programs
  /* jshint ignore:start */
  localStorage.removeItem("prog");
  localStorage.removeItem("progparam");
  localStorage.removeItem("progsecurity");
  localStorage.removeItem("progtrans");
  localStorage.removeItem("progtype");
  localStorage.removeItem("progwhstype");
  localStorage.removeItem("programSendsTransactions");
  localStorage.removeItem("programPollsAllocation");
  localStorage.removeItem("program/parameters");
	/* jshint ignore:end */

	convertKeys();

  //return the call number that this was last changed in. Does nothing for now but good reference number.
  return "106008";
}

function convertKeys() {
  "use strict";

	//Storage update needs to convert keys that were named badly before.
	//Spelling mistakes are expected in the old keys.
	convertVariableKey("timeout", "settings/timeout", true);
	convertVariableKey("requestTimeout","settings/requestTimeout", true);
	convertVariableKey("customLocationMappings", "data/customLocationMappings", true);
	convertVariableKey("downloadallallocations", "settings/downloadAllAllocations", true);
	convertVariableKey("makes","data/makes",true);
	convertVariableKey("racklimit", "settings/rackLimit", true);
	convertVariableKey("racklimitlevel", "settings/rackLimitLevel", true);
	convertVariableKey("racklimitwarning", "settings/rackLimitWarning", true);
	convertVariableKey("labels", "data/labels", true);
	convertVariableKey("locations", "data/locations", true);
	convertVariableKey("rackedbay", "settings/overrideRackBay", true);
	convertVariableKey("barcodemap", "settings/classicBarcodeMapping", true);
	convertVariableKey("scanPrefix", "settings/scanPrefix", true);
	convertVariableKey("scanPriority", "settings/scanPriority", true);
	convertVariableKey("confirmScanSound", "settings/confirmScanSound", true);
	convertVariableKey("hideDoubleScanError", "settings/hideDoubleScanError", true);
	convertVariableKey("batchMap", "settings/batchMap", true);
	convertVariableKey("palletSize", "settings/palletSize", true);
	convertVariableKey("useSWA", "settings/useSWA", true);
	convertVariableKey("useWTL", "settings/useWTL", true);
	convertVariableKey("woodTrackingMap", "settings/woodTrackingMap", true);
	convertVariableKey("woodTrackingTagLength", "settings/woodTrackingTagLength", true);
	convertVariableKey("woodTrackingFormat", "settings/woodTrackingFormat", true);
	convertVariableKey("woodTrackingPrefix", "settings/woodTrackingPrefix", true);
	convertVariableKey("woodTrackingValidation", "settings/woodTrackingValidation", true);
	convertVariableKey("useTrailers", "settings/useTrailers", true);
	convertVariableKey("trailerWarehouse", "settings/trailerWarehouse", true);
	convertVariableKey("warehouses", "data/warehouses", true);
	convertVariableKey("dramscanusers", "data/users", true);

	convertVariableKey("scannercoyno", "settings/scannerCompany", true);
	convertVariableKey("location", "runtime/location", true);
  convertVariableKey("locationName", "runtime/locationName", true);
}

/**
 * @description Convert old keys to new keys. This is actually called after a download of updates.
 * The old key will only migrate to a new key if the new key doesn't exits. If it exists, it updated already.
 * @param oldKey
 * @param newKey
 * @param deleteOld
 */
function convertVariableKey(oldKey, newKey, deleteOld) {
	"use strict";
	//todo doesn't remove item if it is blank. Should this check to see if localStorage has own property?
	if (localStorage.getItem(oldKey)) {
		if (!localStorage.getVariable(newKey)) {
			localStorage.setVariable(newKey,localStorage.getVariable(oldKey), false);
		}
		if (deleteOld) {
			localStorage.removeItem(oldKey);
		}
	}
}

/**
 * @description Cleans up any changes to settings if the code updates without a settings update.
 */
function offlineLoginCleanup() {
  //Update the admin keys if not set by the update routine.
  "use strict";
  if (!localStorage.getItem("security/adminLevel")) {
    //If the new key doesn't exist then add it and the exitLevel. After setting update key will exist and this will skip
    localStorage.setVariable("security/adminLevel",localStorage.getItem("adminlevel"), true);
    localStorage.setVariable("security/exitLevel", 90, false);
    localStorage.removeItem("adminlevel");
  }
	convertKeys();
}

function showAjaxLoading() {
  "use strict";
  $('<div id="loading"><img id="loading-image" src="../img/dramscan-ajax-loader.gif" alt="Loading..." /></div>').appendTo("body");
}

function hideAjaxLoading() {
  "use strict";
  $("#loading").remove();
}

/**
 * @typedef {object} response Data from server
 * @property {object} settings Standing Data
 * @property {object} labels Screen labels
 * @property {object} incidents Incident codes
 * @property {object} users Dramscan Users
 * @property {object} locations Scanning locations
 * @property {object} barcodeFormats Custom barcode formats for inconsistent / non-standard barcode
 * @property {Object} palletStartPositions stating whs / bay / rack / level in every pallet warehouse
 * @property {Object} conditionCodes - Cask condition codes used for container receipts of wood.
 * @property {Object} eventGroups - Event groups and event codes used for wood tracking.
 * @property {Object} emptyWoodLocations - Locations that empty wood cask IDs can be scanned into.
 * @property {Object} caskTypes - Cask types
 * @property {Object} woodTypes - Wood types
 * @property {Object} fillingLines - Filling lines for the current location
 * @property {Object} makes - Make codes from name and mapping table. And blend codes.
 * @property {Object} spirits - Spirit codes
 * @property {Object} blends - blend codes
 * @property {Object} transactionCodes - Barcode transaction codes
 * @property {Object} batches - An array of filling batches for scanning CaskIds at filling.
 *
 * @typedef {object} settingsObject Dramscan Standing Data
 * @property {Number} iSessionTime Application Timeout
 * @property {Number} iRequestTimeout Network request timeout seconds
 * @property {boolean} lAllAllocations If downloading all allocations
 * @property {Number} iAdminLevel Admin Security Level
 * @property {Number} iExitLevel Admin security level to exit the application
 * @property {Boolean} lRackLimit IF rack limits are enforced
 * @property {Number} iRackLimit Max qty of casks that can go in a level
 * @property {Number} iRackWarning Warning qty of casks that can go in a level
 * @property {String} cRackedBayTransactions Override the Bay value with Rack value for listed transactions
 * @property {String} cBarcodeLabel Standard SWA label
 * @property {String} cWoodLabel Wood tracking label
 * @property {Number} iWoodLabelTagLength Maximum length of a wood tracking label
 * @property {String} cWoodLabelFormat RexExp format for the wood tracking label
 * @property {String} cBatchLabel RegExp format for the batch mapping
 * @property {Boolean} lUseSWA If using SWA labels
 * @property {Boolean} lUseWTL If using WTL labels
 * @property {Boolean} lUseTrailers If using trailers
 * @property {String} cTrailerWarehouse Code for the trailer warehouse
 * @property {Number} iPalletSize Max qty casks will fit on a pallet
 * @property {Number} iPalletBayCapacity Max qty of stacks of pallets in a bay
 * @property {Number} iPalletStackCapacity Max qty of pallets on a stack
 * @property {String} cScanPrefix Prefix used by wedge software to detect scans
 * @property {String} cBarcodePriority SWA or WTL label priority for displaying when both in use
 * @property {Boolean} lConfirmScanSound If a sound should play when a scan has been validated good
 * @property {Boolean} lHideDoubleScanError If double scan errors should be hidden, useful for scanner with 2+ readers
 * @property {String} cWoodLabelPrefix Prefix expected at the start of WTLs
 * @property {Boolean} lValidateWoodLabel If the WTL must have the exact prefix when scanning
 * @property {Boolean} lCustomBarcodeFormats If using custom SWA and Cask ID barcode formats. Also needed for Tuns.
 * @property {Boolean} useCustomLocationFormats If using custom mappings for location labels.
 * @property {Boolean} lUseLocationMappings If using custom location label mappings rather than per warehouse
 * @property {String} cLocationMappings Comma list of location label mappings
 * @property {String} cCaskLabel Label for "Cask" displayed in screens
 * @property {Boolean} prepopulateManualCaskNumber If the cask number in manual entry should be populated with last val.
 * @property {Boolean} useDespatchAllocations If Despatches are in use then this is set - An allocation could be a despatch.
 * @property {Boolean} trimFieldWhitespace Trims whitespace from location label fields before padding to a correct format.
 * @property {Boolean} useIncidentApproval Forces incidents to be approved by an admin that did not raise incident
 * @property {Boolean} checkPalletStartPositions Checks a location entered is the next available bay/stack/level in a pallet warehouse
 * @property {Boolean} downloadContainers Download and use containers for Wood Tracking.
 * @property {Boolean} downloadDespatches Download and use wood despatches for Wood Tracking.
 * @property {Number} maxTransactions Maximum number of transactions allowed saved on the device at any one time.
 * @property {String} capacityExpiryUnit Unit of measure for expiring warehouse capacity, days or hours.
 * @property {Number} capacityExpiryValue Integer value for expiry of warehouse capacity records.
 * @property {String} capacityHitAction Action to take when the capacity of a warehouse location is reached, warning or stop.
 * @property {Boolean} useRealPallets True when real pallet numbers / labels are scanned, rather than virtual pallets.
 * @property {Boolean} requireWoodCodes True as default and requires wood codes to be selected in Container Receipt and Cask Filling.
 **/
